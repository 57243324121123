@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #001337;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

@keyframes pulsate {
  0% {
    transform-origin: center;
    transform: scale(0.6);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform-origin: center;
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes success-tick {
  0% {
    stroke-dashoffset: 15px;
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 30px;
    opacity: 1;
  }
}
@keyframes success-circle {
  0% {
    stroke-dashoffset: 100px;
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0px;
    opacity: 1;
  }
}

ul.information-list li:nth-child(5) {
  width: 100% !important;
}

/* file upload */
.et_pb_contact_form_label {
  display: block;
  color: black;
  font-weight: bold;
  letter-spacing: 1.2px;
  font-size: 18px;
  padding-bottom: 5px;
  border-radius: 15px;
  overflow: hidden;
}
input[id="profilePic"] {
  display: none;
}
label[for="profilePic"] {
  height: 130px;
  background-repeat: no-repeat;
  background-position: top 18px center;
  position: absolute;
  background-size: 20%;
  color: transparent;
  margin: auto;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  box-sizing: border-box;
  background: url(./assets/icons/upload-bg.svg) no-repeat center center;
}
label[for="profilePic"]:before {
  content: "Upload Documents";
  display: block;
  position: absolute;
  top: 65%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 12px;
}
label[for="profilePic"]:after {
  display: block;
  content: "(Only .jpg, .png files are accepted) ";
  position: absolute;
  bottom: 20px;
  font-size: 10px;
  color: #3298f1;
  font-weight: 500;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
label[for="et_pb_contact_brand_request_0"]:after {
  content: " (Provide link or Upload files if you already have guidelines)";
  font-size: 12px;
  letter-spacing: -0.31px;
  color: #7a7a7a;
  font-weight: normal;
}
label[for="et_pb_contact_design_request_0"]:after {
  content: " (Provide link or Upload design files)";
  font-size: 12px;
  letter-spacing: -0.31px;
  color: #7a7a7a;
  font-weight: normal;
}

label[for="profilePic"] {
  cursor: pointer;
  transition: 400ms ease;
}
.file_names {
  display: block;
  position: absolute;
  color: black;
  left: 0;
  bottom: -30px;
  font-size: 13px;
  font-weight: 300;
}
.file_names {
  text-align: center;
}
input[id="profilePic2"] {
  display: none;
}
label[for="profilePic2"] {
  height: 130px;
  background-repeat: no-repeat;
  background-position: top 18px center;
  position: absolute;
  background-size: 20%;
  color: transparent;
  margin: auto;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  box-sizing: border-box;
  background: url(./assets/icons/upload-bg.svg) no-repeat center center;
}
label[for="profilePic2"]:before {
  content: "Upload Documents";
  display: block;
  position: absolute;
  top: 65%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 12px;
}
label[for="profilePic2"]:after {
  display: block;
  content: "(Only .jpg, .png files are accepted) ";
  position: absolute;
  bottom: 20px;
  font-size: 10px;
  color: #3298f1;
  font-weight: 500;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

label[for="profilePic2"] {
  cursor: pointer;
  transition: 400ms ease;
}
.scan-complete,
.video-page header {
  display: none;
}
.box {
  /* Centering the inner text */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 20px 15px;
}

.border-gradient {
  /* We set the gradient on the element itself */
  background-image: linear-gradient(#680fad, #063bc4);
  /* ensures child position will be absolute within this element's stacking context */
  position: relative;
  /* z-index + position:relative creaates a new stacking context so that we can reorder child elements within this element */
  z-index: 1;
  padding: 20px 15px;
}

.border-gradient::before {
  content: "";
  display: block;
  /* We puth the actual background color for the contents here */
  background-color: #02102b;
  position: absolute;
  /* We need to subtract a percentage equivalent to the amount used for top and left, since this curve is proportionally a little bit more closed than the outer one */
  border-radius: calc(10px - 2px);
  /* the top amount is half of the width remaining space */
  height: 98%;
  width: 99%;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  padding: 20px 15px;
  /* z-index puts the child at the bottom of the stack, since we had created a new stacking context for the parent element this z-index will put the element behind all child content of the element, but not behind it */
  z-index: -1;
}
